<template>
	<div class="classify">
		<div class="header-two">
			<MainNav></MainNav>
		</div>
		<div class="wrapper">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>分类管理</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content shadow">
				<div class="main-title">
					<div class="left"><em></em><span>分类管理</span><i>(可拖拽调整顺序)</i></div>
					<el-button type="warning" @click="addClassify">添加分类</el-button>
				</div>
				<div class="classify-list">
					<div class="classify-item" v-for="(item) in classifyList"
						v-dragging="{ item: item, list: classifyList, group: 'item', otherData: otherData }"
						:key="item.name">
						<span>{{item.name}}</span>
						<i class="el-icon-edit" @click="editClassify(item)"></i>
						<i class="el-icon-close" @click="delClassify(item)"></i>
					</div>
				</div>
			</div>

		</div>
		<MainFooter></MainFooter>
	</div>
</template>

<script>
	import http from "../http.js";
	import MainNav from "../components/header.vue";
	import MainFooter from "../components/footer.vue";
	export default {
		components: {
			MainNav,
			MainFooter,
		},
		data() {
			return {
				classifyList: [],
				category: {
					id: '',
					name: '',
					sort: ''
				},
				sortedList: []
			}
		},
		created() {
			this.getList()
		},
		methods: {
			getList() {
				http.fetchGet('/api/admin/category/list').then(res => {
					if (res.data.code == 0) {
						this.classifyList = res.data.data
					}
				})
			},
			editClassify(item) {
				this.category = Object.assign({}, item)
				this.$prompt('请输入分类名称', '修改分类', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /\S/,
					inputErrorMessage: '请填写分类名称！',
					inputValue: item.name
				}).then(({
					value
				}) => {
					this.handleEdit(value)
				}).catch(() => {

				});
			},
			handleEdit(name) {
				this.category.name = name
				http.fetchPost('/api/admin/category', this.category).then(res => {
					if (res.data.code == 0) {
						this.getList()
						this.$message({
							type: 'success',
							message: '修改成功！'
						});
					} else {
						this.$message.error(res.data.desc)
					}
				})
			},
			delClassify(item) {
				this.$confirm('确定要删除此分类码?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.handleDel(item)
				}).catch(() => {

				});

			},
			handleDel(item) {
				http.fetchPost('/api/admin/category/del', {
					id: item.id
				}).then(res => {
					if (res.data.code == 0) {
						this.getList()
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					} else {
						this.$message.error(res.data.desc)
					}
				})
			},
			addClassify() {
				this.category.id = ''
				this.category.name = ''
				this.category.sort = 1000
				this.$prompt('请输入分类名称', '添加分类', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /\S/,
					inputErrorMessage: '请填写分类名称！'
				}).then(({
					value
				}) => {
					this.handleAdd(value)
				}).catch(() => {

				});
			},
			handleAdd(name) {
				this.category.name = name
				http.fetchPost('/api/admin/category', this.category).then(res => {
					if (res.data.code == 0) {
						this.classifyList.push(res.data.data)
						this.$message({
							type: 'success',
							message: '添加成功！'
						});
					} else {
						this.$message.error(res.data.desc)
					}
				})
			}
		},
		mounted() {
			this.$dragging.$on('dragged', ({
				value
			}) => {
				this.sortedList = []
				let list = value.list
				console.log(list.map(v => v.name))
				list.forEach((item, idx) => {
					item.sort = idx
					this.sortedList.push(Object.assign({}, item))
				})
				console.log(this.sortedList.map(v => v.name))
			})
			this.$dragging.$on('dragend', () => {
				http.fetchPost('/api/admin/category/sort', this.sortedList).then(res => {
					if (res.data.code == 0) {
						this.$message.success('排序成功')
					} else {
						this.$message.error(res.data.desc)
					}
				})
			})
		}

	};
</script>

<style lang="scss">
	@import '~@/assets/css/classify.scss';
</style>
